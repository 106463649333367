<script>
export default {
  name: 'add',
  props: {

  },

  data() {
    return {
      gradeList: [],

      visible: false,
      form: {
      },
      subing: false,
    }
  },

  mounted() {
  },

  methods: {
    show() {
      this.visible = true
      this.getGradeList()
    },

    getGradeList() {
      this.$api.setting.getGradeList({}).then(res=>{
        if(res.data.code === 1){
          this.gradeList = res.data.data.rows;
        }
      })
    },

    submit() {
      this.subing = true
      try {
        this.$api.setting.batchAddTeamUser(this.form).then(res => {
          if (res.data.code === 1) {
            console.log('data', res)
            this.$message.success('账号生成成功')
            this.visible = false
          }
        })
      } finally {
        this.subing = false
      }
    }
  }
}
</script>

<template>
  <el-dialog :visible.sync="visible" title="批量生成账号">
    <el-card>
      <el-form label-width="120px">
        <el-form-item label="年级" :rules="[{ required: true, message: '请选择年级' }]">
          <el-select v-model="form.grade_id" placeholder="请选择" style="width: 75%;">
            <el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="账户名前缀" :rules="[{ required: true, message: '请输入前缀' }]">
          <el-input v-model="form.pre" style="width: 200px" />
        </el-form-item>

<!--        <el-form-item label="账户数量" :rules="[{ required: true, message: '请输入数量' }]">-->
<!--          <el-input-number v-model="form.number" />-->
<!--        </el-form-item>-->

        <el-form-item>
          <div>
            生成的账户名为：前缀+序号
          </div>
          <div>
            密码默认统一为：123456
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" v-loading="subing" @click="submit">提交</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </el-dialog>
</template>

<style scoped lang="scss">

</style>
